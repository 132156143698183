import { post } from "@/utils/http.js";

export const searchActivityInfo = (params) => {
  return post(`/activity/info`, params);
};

export const apply = (params) => {
  return post(`/Activity/Apply`, params);
};

export const addBookCinema = (params) => {
  return post(`/Activity/AddBookCinema`, params);
};