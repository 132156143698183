<template>
  <div>
    <van-nav-bar
      title="电影包场"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    /> 
    <div class="shop-img">
       <img src="http://file.juhaomaish.cn/ActivityDetail/yxyx.jpg" style="width:100%;height:100%"  /> 
    </div>
    <div class="empty"></div> 
    <div class="fixButton">
      <van-button type="primary" class="btnBaoC" :block="true" @click="gotoZC"
        >去专场</van-button>
      <van-button type="primary" class="btnOrder" :block="true" @click="gotoBaoC"
        >去包场</van-button>
    </div>

    <van-popup
      v-model:show="showSignUp"
      position="bottom"
      round
      :style="{ height: '93%', width: '100%' }"
    >
       <van-form @submit="onSubmit" >
        <van-field
          v-model="applier.linkman"
          name="name"
          label="联系人"
          placeholder="请填写联系人"
          :rules="[{ required: true, message: '请填写联系人' }]"
        />
         <van-field
          v-model="applier.mobile"
          name="手机号"
          label="手机号"
          placeholder="请填写手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
       <van-field
          v-model="applier.email"
          name="邮箱"
          label="邮箱"
          placeholder="选填" 
        /> 
         <van-field
          v-model="applier.customerName"
          name="name"
          label="公司名称"
          placeholder="选填" 
        />

    <div class="empty"></div>
         <van-field
          v-model="applier.movieName"
          name="包场影片"
          label="包场影片"
          placeholder="请填写影片名称" 
          readonly 
          :rules="[{ required: true, message: '请填写影片名称' }]"
        />
         <van-field
          v-model="applier.intendedCity"
          name="意向城市"
          label="意向城市"
          placeholder="请填写意向城市"  
          :rules="[{ required: true, message: '请填写意向城市' }]"
           @click="() => (showCityUp = true)"  
        />
            
         <van-field
          v-model="applier.intendedCinema"
          name="意向影院"
          label="意向影院"
          placeholder="选填，我们会优先为您联系意向影院"   
        /> 
         <van-field
          v-model="applier.intendedTime"
          name="意向时间"
          label="意向时间"
          placeholder="请填写意向时间" 
          :rules="[{ required: true, message: '请填写意向时间' }]" 
          @click="() => (showTimeUp = true)"   
        /> 

 
         <van-field v-model="applier.intendedNumber" name="intendedNumber" label="人数"   >
          <template #input>
            <van-stepper v-model="applier.intendedNumber" />
          </template>
        </van-field>
        
         <van-field v-model="applier.intendedAmount" name="预算" label="预算" placeholder="选填" type="digit" />
            
         <van-field
          v-model="applier.remark"
          rows="3"
          autosize
          label="备注"
          type="textarea"
          maxlength="200"
          placeholder="选填,200字以内"
          show-word-limit
        />   
        <div style="margin: 16px">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
        </van-form>
    </van-popup>

    <van-popup v-model:show="showCityUp"  position="bottom" round>
      
      <van-area title="" 
      :area-list="areaList" 
      :columns-placeholder="['请选择', '请选择', '请选择']" 
      @change="onChange" @confirm="onConfirm" @cancel="onCancel" />
    </van-popup>

    <van-popup v-model:show="showTimeUp"  position="bottom" round>
       
<van-datetime-picker
  v-model="currentDate"
  type="datetime"
  title=""
  :min-date="minDate"
  :max-date="maxDate"  
  :columns-order="[ 'year','month', 'day','hour','minute']" 
      @change="onTimeChange" @confirm="onTimeConfirm" @cancel="onTimeCancel"
/>
    </van-popup>
    
  </div>
</template> 
<script>
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {  addBookCinema } from "@/api/active.js";
import { Toast } from "vant";
import { useStore } from "vuex";    
import { areaList } from '@vant/area-data';
export default {
  setup() { 
    const currentDate = ref(new Date(2023, 2, 7,10,0));
    const showTimeUp = ref(false);
    const showCityUp = ref(false); 
    const router = useRouter();
    const route = useRoute();
    const showSignUp = ref(false);   
    const store = useStore();
    const carmodel=ref('');  
    const applier = reactive({
       sourceId: "",
      movieId: "",
      movieName: "乌衣夜行", 
      customerName: "",
      linkman: "",
      mobile: "",
      email: "",
      intendedCity: "",
      intendedCinema: "",
      intendedTime: "2023-03-09 10:00",
      intendedNumber: 20,
      intendedAmount: null,
      remark: "", 
      openid:"",
      Province:"",
      City:"",
      Area:"",
    });
    onMounted(() => { 
      applier.openid = store.getters["user/getOpenId"];
      if (route.params.id) {
        applier.movieId = route.params.id; 
         if(applier.movieId.length<=19){ 
         showSignUp.value = true;
         }
      }
    });
    const onSubmit = () => { 
      showSignUp.value = false;  
      addBookCinema(applier).then((res) => { 
        Toast({
          message: res.msg, 
          dangerouslyUseHTMLString: true,
          type: 'warning',  
          duration:8000 
        }); 
      });
    
    };
    const onClickLeft = () => {
      router.back();
    };

 
      //value=0改变省，1改变市，2改变区 
    var  onChange=(val)=>{  
      console.log(val);//查看打印 
       applier.intendedCity=val[0].name+","+val[1].name+","+val[2].name;
       applier.Province=val[0].name;
       applier.City=val[1].name;
       applier.Area=val[2].name;   
    };
    //确定选择城市 
    var onConfirm=(val)=>{ 
      console.log(val);
       applier.intendedCity=val[0].name+","+val[1].name+","+val[2].name;
       applier.Province=val[0].name;
       applier.City=val[1].name;
       applier.Area=val[2].name;
       showCityUp.value=false;
    };
    //取消选中城市 
   var onCancel=()=>{ 
       //applier.intendedCity='';
       //applier.Province='';
       //applier.City='';
       //applier.Area='';
       showCityUp.value=false;
    };


    
      //value=0改变省，1改变市，2改变区 
    var  onTimeChange=(val)=>{  
      console.log(val);//查看打印 
       
    };
    //确定选择城市 
    var onTimeConfirm=(val)=>{ 
      console.log(val);

      var date = new Date(val);
var year = date.getFullYear();
var month = date.getMonth() + 1;
var strDate = date.getDate();
var hour = date.getHours();
var minute = date.getMinutes(); 
month = month > 9 ? month : '0' + month;
strDate = strDate > 9 ? strDate : '0' + strDate;
hour = hour > 9 ? hour : '0' + hour;
minute = minute > 9 ? minute : '0' + minute; 
  applier.intendedTime = year + '-' + month + '-' + strDate + ' ' + hour + ':' + minute;
 
       showTimeUp.value=false;
    };
    //取消选中城市 
   var onTimeCancel=()=>{  
       showTimeUp.value=false;
    };
  
    const gotoZC = () => { 
      applier.intendedNumber=20;
      showSignUp.value=true;
    }; 
    const gotoBaoC = () => { 
      applier.intendedNumber=60;
       showSignUp.value=true;
    }; 
    return { onClickLeft, showSignUp, applier,showCityUp,areaList,carmodel,showTimeUp,
     minDate: new Date(2023, 2, 9),
      maxDate: new Date(2023, 3, 30),
      currentDate,
    onChange,onConfirm,onCancel,onTimeChange,onTimeConfirm,onTimeCancel,gotoZC,gotoBaoC, onSubmit };
  },
};
</script> 
<style lang="scss" scoped>
.shop-header {
  width: 100%;
  background: transparent;
  background-color: #fff;
  text-align: center;
}
.shop-info {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  background-color: #fff;
}
.shop-title {
  font-size: 1.3rem;
}
.shop-subtitle {
  display: flex;
  line-height: 1.5rem;
  margin-top: 0.5rem;
}
.shop-img > div > img {
  width: 100%;
}
.shop-price {
  padding-right: 0.3rem;
  font-size: 0.9rem;
}
.shop-showtime {
  margin-left: 1rem;
  font-size: 0.7rem;
}
.shop-curry {
  padding-left: 0.7rem;
  color: #ff9600;
  font-size: 0.5rem;
  font-weight: normal;
}
.price {
  font-weight: bold;
  font-size: 1.3rem;
  color: #ff9600;
}
.shop-label {
  margin-left: 2rem;
}
.goodlabel {
  background-color: #ff9600;
  color: #ffffff;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  margin-block-start: 0em;
  margin-block-end: 0em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0rem 0.3rem;
}
.shop-movie {
  padding: 0.5rem 0.7rem;
  margin-top: 0.5rem;
  background-color: #fff;
  border-radius: 0.3rem;
}
.empty {
  height: 20px;
}
.fixButton {
  position: fixed;
  width: 100%;
  bottom: 50px;
}
.shop-remark {
  line-height: 2rem;
  text-align: center;
  color: #b2b2b2;
  font-size: 0.7rem;
}

.clear {
  clear: both;
} 

.btnBaoC{
  width: 50% !important;
  float: left;
  border: solid 1px #2C9EA8 !important;
  background-color: #2C9EA8 !important;
}
.btnOrder{
  width: 50% !important;
  float: left;
}
</style>