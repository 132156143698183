<template>
  <div>
    <van-nav-bar
      title="活动"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- <van-search
      v-model="value"
      placeholder="请输入搜索关键词"
      input-align="center"
    /> -->
    <card-list :api="searchActive" :apiParams="{ keyword: value }">
      <template v-slot:default="defaultProps">
        <!-- {{ defaultProps }} -->
        <div class="item" @click="gotoActive(defaultProps.item)">
          <div class="image" style="">
            <img :src="defaultProps.item.imgUrl" />
          </div>
          <div class="item-desc">
            <div class="desc">
              <p class="movie-title">{{ defaultProps.item.activityName }}</p>
              <p class="movie-subtitle">
                {{ defaultProps.item.subTitle }}
              </p>
            </div>
            <div class="on-movie">
              {{ defaultProps.item.CouponStatus }}
            </div>
          </div>
        </div>
        <!-- {{ defaultProps }} -->
      </template>
    </card-list>
  </div>
</template>

<script>
import { searchActive } from "@/api/home.js";
import { ref } from "vue";
import { useRouter } from "vue-router";
import CardList from "@/components/CardList.vue";
export default {
  components: { CardList },
  setup() {
    const value = ref(null);
    const router = useRouter();
    const onClickLeft = () => {
      router.back();
    };
    const gotoActive = (item) => {
      router.push({ path: `/active/detail/${item.activityId}` });
    };
    return { searchActive, value, onClickLeft,gotoActive };
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-radius: 0.85em;
  border: 1px solid #efefef;
  box-shadow: 8px 5px 5px 8px #efefef;
  background: #ffffff;
}
.movie-title {
  font-size: 0.9rem;
  font-weight: 600;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.desc {
  width: 100%;
}
.image {
  width: 100%;
  border-radius: 0.85em;
}
.image > img {
  border-radius: 0.85em 0.85em 0 0;
  width: 100%;
  height: 100%;
}
.movie-subtitle {
  font-size: 0.75rem;
  font-weight: 400;
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: #a0a0a0;
}
.on-movie {
  width: 5rem;
  text-align: right;
  height: 3rem;
  line-height: 3rem;
}
.item-desc {
  display: flex;
  padding: 0.3rem 1rem 0.3rem 1rem;
}
::v-deep .van-cell {
  background-color: transparent;
}
</style>