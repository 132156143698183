<template>
  <div>
    <van-nav-bar
      title="电影列表"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <card-list :api="listApi">
      <template v-slot:default="defaultProps">
        <div class="item" @click="gotoMovie(defaultProps.item.movieId)">
          <div class="image">
            <img :src="defaultProps.item.imgListUrl" />
          </div>
          <div class="item-desc">
            <div class="desc">
              <p class="movie-title">{{ defaultProps.item.movieTitle }}</p>
              <p class="movie-subtitle">
                {{ defaultProps.item.movieSubTitle }}
              </p>
            </div>
            <div class="on-movie end">{{ defaultProps.item.movieStatus }}</div>
          </div>
        </div>
      </template>
    </card-list>
  </div>
</template>

<script>
import CardList from "@/components/CardList.vue";
import { searchMovies } from "@/api/movie.js";
import { useRouter } from "vue-router";
export default {
  components: { CardList },
  setup() {
    const router = useRouter();
    const listApi = () => {
      return searchMovies();
    };

    const gotoMovie = (id) => {
      router.push({
        path: `/movie/detail/${id}`,
      });
    };
    const onClickLeft = () => {
      router.back();
    };

    return { listApi, gotoMovie, onClickLeft };
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-radius: 0.85em;
  border: 1px solid #efefef;
  box-shadow: 8px 5px 5px 8px #efefef;
  background: #ffffff;
}
.movie-title {
  font-size: 0.9rem;
  font-weight: 600;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.desc {
  width: 100%;
}
.image {
  width: 100%;
  border-radius: 0.85em;
}
.image > img {
  border-radius: 0.85em 0.85em 0 0;
  width: 100%;
  height: 100%;
}
.movie-subtitle {
  font-size: 0.9rem;
  font-weight: 400;
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: #a0a0a0;
}
.on-movie {
  width: 10rem;
  text-align: right;
  height: 3rem;
  line-height: 3rem;
}
.show{
  color:#ff9600;
  font-weight: bold;
}
.end{
  color:gray;
  font-weight: bold;
}
.item-desc {
  display: flex;
  padding: 0.3rem 1rem 0.3rem 1rem;
}
::v-deep .van-cell {
  background-color: transparent;
}
</style>